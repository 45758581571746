<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-card>
          <v-tabs
            v-model="tab"
            class="mb-9"
            grow
          >
            <v-tab>Receipts</v-tab>
            <v-tab>Invoices</v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items
      id="spd-tab-items"
      v-model="tab"
    >
      <v-tab-item>
        <v-card id="spd-receipt-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

            <div class="row text-right pb-sm-6 pb-5">
              <div class="col-sm-3 pa-2 col-6">
                <!-- filter account -->
                <v-combobox
                  v-model="accountFilters"
                  :items="accounts"
                  item-text="name"
                  single-line
                  multiple
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Account')"
                  :class="{ 'receipt-list-account': $vuetify.breakpoint.smAndUp }"
                ></v-combobox>
              </div>

              <div class="row pa-2">
                <div class="col-6 pr-2">
                  <v-autocomplete
                    v-model="receiptToggleSelected"
                    :items="branchOptions"
                    item-text="name"
                    item-value="value"
                    single-line
                    outlined
                    dense
                    hide-details
                    clearable
                    :placeholder="t('Select Branch Status')"
                    :class="{ 'spd-list-branch': $vuetify.breakpoint.smAndUp }"
                    @change="receiptActiveToggleChange()"
                  ></v-autocomplete>
                </div>

                <div class="col-6 pl-2">
                  <!-- filter branch -->
                  <v-combobox
                    v-model="receiptBranchFilters"
                    :items="receiptBranches"
                    item-text="name"
                    single-line
                    multiple
                    outlined
                    dense
                    hide-details
                    clearable
                    :placeholder="t('Select Branch')"
                    :class="{ 'spd-list-branch': $vuetify.breakpoint.smAndUp }"
                  ></v-combobox>
                </div>
              </div>

              <div class="col-sm-3 col-12 pa-2">
                <!-- filter insurance -->
                <v-combobox
                  v-model="insuranceFilters"
                  :items="insurances"
                  item-text="name"
                  single-line
                  multiple
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Insurances')"
                  :class="{ 'receipt-list-insurances': $vuetify.breakpoint.smAndUp }"
                ></v-combobox>
              </div>
            </div>
          </v-card-text>

          <v-card-text class="row pb-0 pr-0">
            <div class="col-sm-2 col-12 pb-sm-8 pb-1 pl-2 pr-1">
              <!-- Date range -->
              <v-menu
                v-model="isReceiptDateMenuOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :append-icon="icons.mdiCalendar"
                    :value="receiptDateRangeText"
                    readonly
                    dense
                    outlined
                    hide-details
                    class="me-4"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="receiptDateRange"
                  color="primary"
                  :first-day-of-week="1"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    block
                    @click="receiptDateRangeChange()"
                  >
                    {{ t('Set Dates') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center col-sm-4 col-12 pb-sm-8 pb-6 pr-1">
              <!-- search receipt -->
              <v-tooltip
                :left="$vuetify.breakpoint.smAndUp"
                :top="$vuetify.breakpoint.xsOnly"
                :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                max-width="250"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="30"
                    class="mr-3 hover-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="25">
                      {{ icons.mdiAlertCircleOutline }}
                    </v-icon>
                  </v-avatar>
                </template>
                <h3 class="my-2">
                  {{ t('How to use the search bar') }}
                </h3>
                <p class="mb-1">
                  {{ t('You can search using') }}:
                </p>
                <ul class="mb-2">
                  <li>
                    {{ t('#Receipt No.') }}
                  </li>
                  <li>
                    {{ t('Insurance Policy No.') }}
                  </li>
                  <li>
                    {{ t('Car Registration No.') }}
                  </li>
                  <li>
                    {{ t('Customer Name') }}
                  </li>
                </ul>
              </v-tooltip>
              <v-text-field
                v-model="receiptSearchText"
                :append-icon="icons.mdiMagnify"
                single-line
                dense
                outlined
                hide-details
                :placeholder="t('Search Receipt')"
                :class="{ 'receipt-list-search': $vuetify.breakpoint.mdAndUp }"
              ></v-text-field>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="receiptTablePagination.per"
                    :items="receiptListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="spd-list-row-selection"
                    @input="receiptListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="receiptTablePagination.current_page"
                  total-visible="6"
                  :length="receiptTablePagination.total_page"
                  @input="receiptPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            v-model="selectedReceipts"
            :headers="receiptTableColumns"
            :items="receipts"
            :options.sync="receiptOptions"
            :items-per-page="100"
            :loading="loading"
            mobile-breakpoint="0"
            hide-default-footer
            show-select
            :disable-sort="true"
            class="text-no-wrap"
            @item-selected="selectedChange($event)"
            @toggle-select-all="toggleAll($event)"
          >
            <!-- Receipt Link -->
            <template #[`item.receipt_url`]="{item}">
              <a
                v-if="item.receipt_url !== 'NULL'"
                :href="item.receipt_url"
                target="_blank"
                style="text-decoration: none;"
              >
                {{ item.receipt_url }}
              </a>
            </template>

            <!-- Full SPD No. -->
            <template #[`item.full_spd_number`]="{item}">
              <div class="d-flex align-center justify-center">
                <v-text-field
                  v-model="item.full_spd_number"
                  type="number"
                  single-line
                  dense
                  outlined
                  hide-details="auto"
                  :disabled="!can('write', 'SpdReport')"
                  class="receipt-payment my-3"
                ></v-text-field>
                <v-btn
                  icon
                  small
                  :disabled="!can('write', 'SpdReport')"
                  class="ml-2"
                  @click="receiptValueChange(item.id, item.full_spd_number, 'full_spd_number')"
                >
                  <v-icon size="18">
                    {{ icons.mdiContentSave }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center">
                <!-- View Insurance Policy -->
                <v-tooltip
                  v-if="item.insurance_policy_url !== 'NULL'"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :href="item.insurance_policy_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View insurance policy</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        icon
                        small
                        disabled
                      >
                        <v-icon size="18">
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>No insurance policy attached</span>
                </v-tooltip>
              </div>
            </template>

            <!-- Receipt Number -->
            <template #[`item.receipt_number`]="{item}">
              <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'apps-receipt-preview', params: { id: item.id } }"
              >
                {{ item.receipt_number }}
              </router-link>
            </template>

            <!-- Invoice Number -->
            <template #[`item.invoice_number`]="{item}">
              <router-link
                v-if="item.invoice_number !== 'NULL'"
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'apps-invoice-preview', params: { id: item.invoice_id } }"
              >
                {{ item.invoice_number }}
              </router-link>
              <p
                v-else
                class="mb-0"
              >
                NULL
              </p>
            </template>
          </v-data-table>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="receiptTablePagination.per"
                    :items="receiptListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="spd-list-row-selection"
                    @input="receiptListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="receiptTablePagination.current_page"
                  total-visible="6"
                  :length="receiptTablePagination.total_page"
                  @input="receiptPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="text-right">
          <v-btn
            color="primary"
            dark
            :block="$vuetify.breakpoint.xsOnly"
            :loading="receiptLoadingPrint"
            :disabled="receiptLoadingPrint"
            class="receipt-button mt-5"
            :class="{ 'loading-color': receiptLoadingPrint && !$vuetify.theme.dark }"
            @click="receiptPrintReport('spd_report')"
          >
            <v-icon
              v-if="!receiptLoadingPrint"
              size="18"
              class="me-2"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <span>{{ t('Print Report') }}</span>
          </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-card id="spd-invoice-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

            <div class="row text-right pb-sm-6 pb-5">
              <div class="col-sm-3 pa-2 col-6 offset-sm-3">
                <!-- filter active/inactive branch -->
                <v-autocomplete
                  v-model="invoiceToggleSelected"
                  :items="branchOptions"
                  item-text="name"
                  item-value="value"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Branch Status')"
                  :class="{ 'spd-list-branch': $vuetify.breakpoint.smAndUp }"
                  @change="invoiceActiveToggleChange()"
                ></v-autocomplete>
              </div>

              <div class="col-sm-3 col-6 pa-2">
                <!-- filter branch -->
                <v-combobox
                  v-model="invoiceBranchFilters"
                  :items="invoiceBranches"
                  item-text="name"
                  single-line
                  multiple
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Branch')"
                  :class="{ 'spd-list-branch': $vuetify.breakpoint.smAndUp }"
                ></v-combobox>
              </div>

              <div class="col-sm-3 col-6 pa-2">
                <!-- filter status -->
                <v-select
                  v-model="statusFilter"
                  :items="statusOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Status')"
                  :class="{ 'invoice-list-status': $vuetify.breakpoint.smAndUp }"
                ></v-select>
              </div>
            </div>
          </v-card-text>

          <v-card-text class="row pb-0 pr-0">
            <div class="col-sm-2 col-12 pb-sm-8 pb-1 pl-2 pr-1">
              <!-- Date range -->
              <v-menu
                v-model="isInvoiceDateMenuOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :append-icon="icons.mdiCalendar"
                    :value="invoiceDateRangeText"
                    readonly
                    dense
                    outlined
                    hide-details
                    class="me-4"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoiceDateRange"
                  color="primary"
                  :first-day-of-week="1"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    block
                    @click="invoiceDateRangeChange()"
                  >
                    Set dates
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center col-sm-4 col-12 pb-sm-8 pb-6 pr-1">
              <!-- search invoice -->
              <v-tooltip
                :left="$vuetify.breakpoint.smAndUp"
                :top="$vuetify.breakpoint.xsOnly"
                :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                max-width="250"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="30"
                    class="mr-3 hover-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="25">
                      {{ icons.mdiAlertCircleOutline }}
                    </v-icon>
                  </v-avatar>
                </template>
                <h3 class="my-2">
                  {{ t('How to use the search bar') }}
                </h3>
                <p class="mb-1">
                  {{ t('You can search using') }}:
                </p>
                <ul class="mb-2">
                  <li>
                    {{ t('#Invoice No.') }}
                  </li>
                  <li>
                    {{ t('Car Registration No.') }}
                  </li>
                  <li>
                    {{ t('Customer Name') }}
                  </li>
                </ul>
              </v-tooltip>
              <v-text-field
                v-model="invoiceSearchText"
                :append-icon="icons.mdiMagnify"
                single-line
                dense
                outlined
                hide-details
                :placeholder="t('Search Invoice')"
                :class="{ 'invoice-list-search': $vuetify.breakpoint.mdAndUp }"
              ></v-text-field>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="invoiceTablePagination.per"
                    :items="invoiceListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="spd-list-row-selection"
                    @input="invoiceListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="invoiceTablePagination.current_page"
                  total-visible="6"
                  :length="invoiceTablePagination.total_page"
                  @input="invoicePageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            :headers="invoiceTableColumns"
            :items="invoices"
            :options.sync="invoiceOptions"
            :items-per-page="100"
            :loading="loading"
            mobile-breakpoint="0"
            hide-default-footer
            :disable-sort="true"
            class="text-no-wrap"
          >
            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center">
                <!-- View Insurance Policy -->
                <v-tooltip
                  v-if="item.insurance_policy_url !== 'NULL'"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :href="item.insurance_policy_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View insurance policy</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        icon
                        small
                        disabled
                      >
                        <v-icon size="18">
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>No insurance policy attached</span>
                </v-tooltip>
              </div>
            </template>

            <!-- Full SPD No. -->
            <template #[`item.full_spd_number`]="{item}">
              <div class="d-flex align-center justify-center">
                <v-text-field
                  v-model="item.full_spd_number"
                  type="number"
                  single-line
                  dense
                  outlined
                  hide-details="auto"
                  :disabled="!can('write', 'SpdReport')"
                  class="receipt-payment my-3"
                ></v-text-field>
                <v-btn
                  icon
                  small
                  :disabled="!can('write', 'SpdReport')"
                  class="ml-2"
                  @click="invoiceValueChange(item.id, item.full_spd_number, 'full_spd_number')"
                >
                  <v-icon size="18">
                    {{ icons.mdiContentSave }}
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <!-- Invoice Number -->
            <template #[`item.invoice_number`]="{item}">
              <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'apps-invoice-preview', params: { id: item.id } }"
              >
                {{ item.invoice_number }}
              </router-link>
            </template>
          </v-data-table>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="invoiceTablePagination.per"
                    :items="invoiceListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="spd-list-row-selection"
                    @input="invoiceListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="invoiceTablePagination.current_page"
                  total-visible="6"
                  :length="invoiceTablePagination.total_page"
                  @input="invoicePageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="text-right">
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :loading="invoiceLoadingPrint"
            :disabled="invoiceLoadingPrint"
            color="primary"
            class="invoice-button mt-5"
            :class="{ 'loading-color': invoiceLoadingPrint && !$vuetify.theme.dark }"
            @click="invoicePrintReport('spd_report')"
          >
            <v-icon
              v-if="!invoiceLoadingPrint"
              size="18"
              class="me-2"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <span>{{ t('Print Report') }}</span>
          </v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiContentSave,
} from '@mdi/js'
import {
  onUnmounted,
  onMounted,
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import ability from '@/plugins/acl/ability'
import receiptStoreModule from '../receipt/receiptStoreModule'
import invoiceStoreModule from '../invoice/invoiceStoreModule'

export default {
  components: {
  },
  setup() {
    // Initializers
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const isSpdReporter = ability.can('write', 'SpdReport')
    const format = isSpdReporter ? 'pdf' : 'csv'

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // Table Handlers
    const receiptTableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: can('read', 'SpdReport') ? 'center' : ' d-none' },
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('Issue Date'), value: 'issued_at' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
        { text: t('Insurance Category'), value: 'insurance_category.name' },
        { text: t('SPD Reference No.'), align: 'right', value: 'full_spd_number' },
        { text: t('#Invoice No.'), value: 'invoice_number' },
        { text: t('General Remarks'), value: 'general_remarks' },
      ]
    ))

    const invoiceTableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: can('read', 'SpdReport') ? 'center' : ' d-none' },
        { text: t('#Invoice No.'), value: 'invoice_number' },
        { text: t('Issue Date'), value: 'created_at' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
        { text: t('Insurance Category'), value: 'insurance_category.name' },
        { text: t('SPD Reference No.'), align: 'right', value: 'full_spd_number' },
        { text: t('General Remarks'), value: 'general_remarks' },
      ]
    ))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipts = ref([])
    const selectedReceipts = ref([])
    const excludedReceipts = ref([])
    const selectAll = ref(false)
    const invoices = ref([])
    const receiptTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const invoiceTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const receiptSearchText = ref('')
    const invoiceSearchText = ref('')
    const receiptOptions = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const invoiceOptions = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const loading = ref(false)
    const receiptLoadingPrint = ref(false)
    const invoiceLoadingPrint = ref(false)
    const statusFilter = ref('Outstanding')
    const statusOptions = ref(['Outstanding', 'Paid'])
    const receiptBranchFilters = ref([])
    const invoiceBranchFilters = ref([])
    const receiptToggleSelected = ref('active')
    const branchOptions = ref([
      { name: 'Ongoing branches', value: 'active' },
      { name: 'Closed branches', value: 'inactive' },
    ])
    const invoiceToggleSelected = ref('active')
    const insuranceFilters = ref([])
    const accountFilters = ref([])
    const receiptDateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const invoiceDateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const receiptListLengthOptions = ref([20, 50, 100])
    const invoiceListLengthOptions = ref([20, 50, 100])
    const isReceiptDateMenuOpen = ref(false)
    const isInvoiceDateMenuOpen = ref(false)
    const accounts = ref([])
    const outstandingCount = ref(null)
    const tab = ref(null)

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const receiptBranches = computed(() => {
      switch (receiptToggleSelected.value) {
        case 'active':
          return store.state.branches.filter(b => b.active)
        case 'inactive':
          return store.state.branches.filter(b => b.active === false)
        default:
          return store.state.branches
      }
    })
    const invoiceBranches = computed(() => {
      switch (invoiceToggleSelected.value) {
        case 'active':
          return store.state.branches.filter(b => b.active)
        case 'inactive':
          return store.state.branches.filter(b => b.active === false)
        default:
          return store.state.branches
      }
    })
    const insurances = computed(() => store.state.insurances)
    const receiptDateRangeText = computed(() => {
      if (Date.parse(receiptDateRange.value[0]) > Date.parse(receiptDateRange.value[1])) {
        const [start, end] = receiptDateRange.value
        receiptDateRange.value[0] = end
        receiptDateRange.value[1] = start
      }

      const startDateText = formatDate(receiptDateRange.value[0])
      const showEndDate = receiptDateRange.value[0] !== receiptDateRange.value[1] && formatDate(receiptDateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(receiptDateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    const invoiceDateRangeText = computed(() => {
      if (Date.parse(invoiceDateRange.value[0]) > Date.parse(invoiceDateRange.value[1])) {
        const [start, end] = invoiceDateRange.value
        invoiceDateRange.value[0] = end
        invoiceDateRange.value[1] = start
      }

      const startDateText = formatDate(invoiceDateRange.value[0])
      const showEndDate = invoiceDateRange.value[0] !== invoiceDateRange.value[1] && formatDate(invoiceDateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(invoiceDateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchPendingSpd = () => {
      store
        .dispatch('fetchPendingSpd')
        .then(response => {
          const { data } = response.data
          store.dispatch('setPendingSpd', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pending spd receipts. Please refresh!')
        })
    }
    const fetchReceipts = () => {
      store
        .dispatch('app-receipt/fetchReceipts', {
          page: receiptTablePagination.value.current_page,
          per: receiptTablePagination.value.per,
          search_text: receiptSearchText.value,
          user_ids: accountFilters.value.map(account => account.id),
          branch_ids: receiptBranchFilters.value.map(branch => branch.id),
          insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
          start_date: receiptDateRange.value[0],
          end_date: receiptDateRange.value[1] ? receiptDateRange.value[1] : receiptDateRange.value[0],
          spd_list: true,
          branch_toggle_filter: receiptToggleSelected.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          receipts.value = data
          receipts.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (key === 'net_payment') return
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          if (selectAll.value) {
            receipts.value.forEach(receipt => {
              if (!excludedReceipts.value.includes(receipt.id)) selectedReceipts.value.push(receipt)
            })
          }
          receiptTablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipts. Please refresh!')
        })
    }
    const receiptPageChange = () => {
      isLoading()
      fetchReceipts()
    }
    const receiptListLengthChange = () => {
      receiptTablePagination.value.current_page = 1
      isLoading()
      fetchReceipts()
    }
    const receiptDateRangeChange = () => {
      isReceiptDateMenuOpen.value = false
      receiptTablePagination.value.current_page = 1
      isLoading()
      fetchReceipts()
    }
    const receiptActiveToggleChange = () => {
      receiptBranchFilters.value = []
    }
    const receiptValueChange = (id, value, type) => {
      if (value === null) return

      store
        .dispatch('app-receipt/updateValue', {
          id,
          receipt: { [type]: value },
          value_type: type,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchPendingSpd()
        })
        .catch(error => snackbarService.error(error.response.data.message || `Something went wrong while updating ${type} . Please refresh!`))
    }
    const receiptPrintReport = (mode = '') => {
      receiptLoadingPrint.value = true
      const receiptIds = []
      selectedReceipts.value.forEach(receipt => {
        receiptIds.push(receipt.id)
      })
      store
        .dispatch('app-receipt/printReport', {
          search_text: receiptSearchText.value,
          user_ids: accountFilters.value.map(account => account.id),
          branch_ids: receiptBranchFilters.value.map(branch => branch.id),
          insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
          receipt_ids: receiptIds,
          excluded_receipt_ids: excludedReceipts.value,
          select_all: selectAll.value,
          start_date: receiptDateRange.value[0],
          end_date: receiptDateRange.value[1] ? receiptDateRange.value[1] : receiptDateRange.value[0],
          branch_toggle_filter: receiptToggleSelected.value,
          spd_list: true,
          mode,
          format,
        })
        .then(response => {
          if (response.type === 'application/json') {
            response.text().then(res => {
              const error = JSON.parse(res)
              snackbarService.error(`${error.message} ${error.receipt_ids || ''}`, -1)
            })
          } else {
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.href = url
            a.download = `spd-receipt(${receiptDateRangeText.value}).${format}`
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
          receiptLoadingPrint.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const selectedChange = event => {
      if (selectAll.value) {
        if (!event.value) {
          excludedReceipts.value.push(event.item.id)
        } else {
          const index = excludedReceipts.value.indexOf(event.item.id)
          if (index > -1) excludedReceipts.value.splice(index, 1)
        }
      }
    }
    const toggleAll = event => {
      if (!event.value) {
        selectedReceipts.value = []
        excludedReceipts.value = []
        selectAll.value = false
      } else {
        excludedReceipts.value = []
        selectAll.value = true
      }
    }
    const fetchInvoices = () => {
      store
        .dispatch('app-invoice/fetchInvoices', {
          page: invoiceTablePagination.value.current_page,
          per: invoiceTablePagination.value.per,
          search_text: invoiceSearchText.value,
          branch_ids: invoiceBranchFilters.value.map(branch => branch.id),
          start_date: invoiceDateRange.value[0],
          end_date: invoiceDateRange.value[1] ? invoiceDateRange.value[1] : invoiceDateRange.value[0],
          status: statusFilter.value,
          branch_toggle_filter: invoiceToggleSelected.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          invoices.value = data
          invoices.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          invoiceTablePagination.value = pagination
          outstandingCount.value = response.data.outstanding_count
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoices. Please refresh!')
        })
    }
    const invoicePageChange = () => {
      isLoading()
      fetchInvoices()
    }
    const invoiceListLengthChange = () => {
      invoiceTablePagination.value.current_page = 1
      isLoading()
      fetchInvoices()
    }
    const invoiceDateRangeChange = () => {
      isInvoiceDateMenuOpen.value = false
      invoiceTablePagination.value.current_page = 1
      isLoading()
      fetchInvoices()
    }
    const invoiceActiveToggleChange = () => {
      invoiceBranchFilters.value = []
    }
    const invoiceValueChange = (id, value, type) => {
      if (value === null) return

      store
        .dispatch('app-invoice/updateInvoice', {
          id,
          invoice: { [type]: value },
          value_type: type,
        })
        .then(response => snackbarService.success(response.data.message))
        .catch(error => snackbarService.error(error.response.data.message || `Something went wrong while updating ${type} . Please refresh!`))
    }
    const invoicePrintReport = (mode = '') => {
      invoiceLoadingPrint.value = true
      store
        .dispatch('app-invoice/printReport', {
          search_text: invoiceSearchText.value,
          branch_ids: invoiceBranchFilters.value.map(branch => branch.id),
          start_date: invoiceDateRange.value[0],
          end_date: invoiceDateRange.value[1] ? invoiceDateRange.value[1] : invoiceDateRange.value[0],
          status: statusFilter.value,
          branch_toggle_filter: invoiceToggleSelected.value,
          spd_list: true,
          mode,
          format,
        })
        .then(response => {
          if (response.type === 'application/json') {
            response.text().then(res => {
              const error = JSON.parse(res)
              snackbarService.error(`${error.message} ${error.invoice_ids || ''}`, -1)
            })
          } else {
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.href = url
            a.download = `spd-invoice(${invoiceDateRangeText.value}).${format}`
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
          invoiceLoadingPrint.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const fetchClerks = () => {
      store
        .dispatch('app-receipt/fetchClerks', { role: [1, 4, 6] })
        .then(response => {
          const { data } = response.data
          accounts.value = data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong while fetching accounts. Please refresh!'))
    }

    // Watch
    let receiptTimer = null
    watch([receiptSearchText, receiptBranchFilters, insuranceFilters, accountFilters, receiptOptions], () => {
      if (receiptTimer) {
        clearTimeout(receiptTimer)
        receiptTimer = null
      }
      receiptTimer = setTimeout(() => {
        if (tab.value === 0) {
          isLoading()
          fetchReceipts()
        }
      }, 800)
    })

    let invoiceTimer = null
    watch([invoiceSearchText, statusFilter, invoiceBranchFilters, invoiceOptions], () => {
      if (invoiceTimer) {
        clearTimeout(invoiceTimer)
        invoiceTimer = null
      }
      invoiceTimer = setTimeout(() => {
        if (tab.value === 1) {
          isLoading()
          fetchInvoices()
        }
      }, 200)
    })

    onMounted(() => {
      fetchClerks()
      fetchPendingSpd()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      loading,
      receiptLoadingPrint,
      invoiceLoadingPrint,
      receipts,
      selectedReceipts,
      invoices,
      accounts,
      receiptBranches,
      invoiceBranches,
      insurances,
      receiptTablePagination,
      invoiceTablePagination,
      receiptTableColumns,
      invoiceTableColumns,
      isReceiptDateMenuOpen,
      isInvoiceDateMenuOpen,
      receiptListLengthOptions,
      invoiceListLengthOptions,
      receiptOptions,
      invoiceOptions,
      receiptSearchText,
      invoiceSearchText,
      receiptBranchFilters,
      branchOptions,
      receiptToggleSelected,
      invoiceBranchFilters,
      invoiceToggleSelected,
      insuranceFilters,
      statusFilter,
      statusOptions,
      accountFilters,
      receiptDateRange,
      invoiceDateRange,
      receiptDateRangeText,
      invoiceDateRangeText,
      outstandingCount,
      tab,
      fetchReceipts,
      fetchInvoices,
      receiptPageChange,
      invoicePageChange,
      receiptListLengthChange,
      invoiceListLengthChange,
      receiptDateRangeChange,
      receiptActiveToggleChange,
      invoiceDateRangeChange,
      invoiceActiveToggleChange,
      formatDate,
      receiptValueChange,
      invoiceValueChange,
      receiptPrintReport,
      selectedChange,
      toggleAll,
      invoicePrintReport,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiContentSave,
        mdiFileDocumentOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #spd-receipt-list, #spd-invoice-list {
    .receipt-list-search {
      width: 32.5rem;
    }

    .invoice-list-search {
      width: 29rem;
    }

    .invoice-list-status {
      max-width: 12.2rem;
    }

    .receipt-list-insurances {
      max-width: 33rem;
    }

    .spd-list-branch {
      max-width: 33rem;
    }

    .receipt-list-account {
      max-width: 33rem;
    }

    .spd-list-row-selection {
      max-width: 5.3rem;
    }

    .receipt-payment {
      min-width: 10rem;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .receipt-payment {
        width: 100px;
      }
    }
  }

  #spd-tab-items {
    background-color: transparent !important;
  }

  .receipt-button, .invoice-button {
    width: 251px;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }
</style>
